<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <div class="drawer-bar auto">
        <div class="fl">
          <span class="font16"><b>Syslog配置</b></span>
        </div>
      </div>
      <div class="department-content auto" id="no-margin-form">
        <div class="box auto">
          <div class="box-title auto">
            <!-- <div class="fl">密码校验管理</div> -->
          </div>
          <div class="box-con auto m-t-20 no-bor">
            <a-form-model
              ref="ruleForm"
              :model="form"
              :rules="rules"
              :label-col="{span: 4}"
              :wrapper-col="{span: 18}"
              labelAlign="left"
              style="max-width: 800px;"
            >
              <a-form-model-item label="Syslog服务器地址" prop="ip">
                <a-input placeholder="请输入服务器 [IP：端口号]" v-model="form.ip" size="small" style="width:324px;margin-left:-0px;"></a-input>
              </a-form-model-item>
              <a-form-model-item label="">
                <a-button type="primary" size="small" style="margin-top:15px;">保存</a-button>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
// import { Group } from '@/model'
export default {
  data () {
    return {
      form: {
        ip: ''
      }
    }
  },
  methods: {
  },
  created () {
  },
  mounted () {
  }
};
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 20px;
}

.box {
}
.box-title .fl {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
  width: 100%;
}
.box-con {
  border-bottom: 1px solid rgba(0,0,0,0.06);
  width: 100%;
  padding-bottom: 20px;
}
.box2 {
  margin-top: 20px;
}
.no-bor {
  border: none;
}

</style>
